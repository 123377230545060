// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./en.json";
import spanish from "./spa.json";
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: english,
    },
    spa: {
      translation: spanish,
    },
  },
  lng: localStorage.getItem('language'), // Set the default language
  fallbackLng: "en", // Fallback language if the translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values by default
  }
});

export default i18n;
