import { React, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import Spinner from "./frontend/Components/Loading/Spinner";
import ReactGA from "react-ga";
import "./assets/styles/Spinner.css";
import "./assets/styles/Navbar.css";
import "./assets/styles/SearchDrop.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

ReactGA.initialize("G-NT8N65EWGL")
const LazyApp = lazy(() => import("./App"))
const root = ReactDOM.createRoot(document.getElementById("main"))
root.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18n}>
      <LazyApp />
    </I18nextProvider>
  </Suspense>
);

reportWebVitals();
